/*
Font families defined by this CSS:
  - InterVariable  variable font for modern web browsers
  - Inter          static fonts for older web browsers (small optical size)
  - InterDisplay   static fonts for older web browsers (large optical size)

Usage example:
  :root { font-family: Inter, sans-serif; }
  @supports (font-variation-settings: normal) {
    :root { font-family: InterVariable, sans-serif; }
  }

*/
@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('web/InterVariable.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('web/InterVariable-Italic.woff2?v=4.0') format('woff2');
}
/* legacy name "Inter var" (Oct 2023) */
@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('web/InterVariable.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: 'Inter var';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('web/InterVariable-Italic.woff2?v=4.0') format('woff2');
}
/* static fonts */
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('web/Inter-Thin.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('web/Inter-ThinItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('web/Inter-ExtraLight.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('web/Inter-ExtraLightItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('web/Inter-Light.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('web/Inter-LightItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('web/Inter-Regular.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('web/Inter-Italic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('web/Inter-Medium.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('web/Inter-MediumItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('web/Inter-SemiBold.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('web/Inter-SemiBoldItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('web/Inter-Bold.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('web/Inter-BoldItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('web/Inter-ExtraBold.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('web/Inter-ExtraBoldItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('web/Inter-Black.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('web/Inter-BlackItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('web/InterDisplay-Thin.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('web/InterDisplay-ThinItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('web/InterDisplay-ExtraLight.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('web/InterDisplay-ExtraLightItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('web/InterDisplay-Light.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('web/InterDisplay-LightItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('web/InterDisplay-Regular.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('web/InterDisplay-Italic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('web/InterDisplay-Medium.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('web/InterDisplay-MediumItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('web/InterDisplay-SemiBold.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('web/InterDisplay-SemiBoldItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('web/InterDisplay-Bold.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('web/InterDisplay-BoldItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('web/InterDisplay-ExtraBold.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('web/InterDisplay-ExtraBoldItalic.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('web/InterDisplay-Black.woff2?v=4.0') format('woff2');
}
@font-face {
  font-family: InterDisplay;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('web/InterDisplay-BlackItalic.woff2?v=4.0') format('woff2');
}
