/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: HelveticaLTWXX-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/helvetica/pro-regular/
 * Copyright: Copyright © 2014 Monotype Imaging Inc. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Incn*/

@font-face {
  font-family: 'CommutersSansLightItalic';
  src: url('CommutersSansLightItalic/font.woff2') format('woff2'), url('CommutersSansLightItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansThin';
  src: url('CommutersSansThin/font.woff2') format('woff2'), url('CommutersSansThin/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansBold';
  src: url('CommutersSansBold/font.woff2') format('woff2'), url('CommutersSansBold/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansSemiBold';
  src: url('CommutersSansSemiBold/font.woff2') format('woff2'), url('CommutersSansSemiBold/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansExtraLight';
  src: url('CommutersSansExtraLight/font.woff2') format('woff2'), url('CommutersSansExtraLight/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansHeavyItalic';
  src: url('CommutersSansHeavyItalic/font.woff2') format('woff2'), url('CommutersSansHeavyItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansThinItalic';
  src: url('CommutersSansThinItalic/font.woff2') format('woff2'), url('CommutersSansThinItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansSemiBoldItalic';
  src: url('CommutersSansSemiBoldItalic/font.woff2') format('woff2'), url('CommutersSansSemiBoldItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansItalic';
  src: url('CommutersSansItalic/font.woff2') format('woff2'), url('CommutersSansItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansExtraBoldItalic';
  src: url('CommutersSansExtraBoldItalic/font.woff2') format('woff2'), url('CommutersSansExtraBoldItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansExtraLightItalic';
  src: url('CommutersSansExtraLightItalic/font.woff2') format('woff2'), url('CommutersSansExtraLightItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansBoldItalic';
  src: url('CommutersSansBoldItalic/font.woff2') format('woff2'), url('CommutersSansBoldItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansLight';
  src: url('CommutersSansLight/font.woff2') format('woff2'), url('CommutersSansLight/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansExtraBold';
  src: url('CommutersSansExtraBold/font.woff2') format('woff2'), url('CommutersSansExtraBold/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansRegular';
  src: url('CommutersSansRegular/font.woff2') format('woff2'), url('CommutersSansRegular/font.woff') format('woff');
}

@font-face {
  font-family: 'CommutersSansHeavy';
  src: url('CommutersSansHeavy/font.woff2') format('woff2'), url('CommutersSansHeavy/font.woff') format('woff');
}
