@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  input {
    @apply focus:ring-accent-500 appearance-none shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-1 focus:ring-inset;
  }

  button {
    @apply font-semibold;
  }

  @tailwind components;
  @tailwind utilities;
}

@import './styles/fonts/intervariable';
@import './styles/fonts/commuter-sans';
@import './styles/primeng';

html {
  font-size: 16px;
}

hr {
  @apply my-8 border-t border-neutral-300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-commuter uppercase;
}

.products-carousel {
  .carousel-control-next {
    opacity: 1;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
}

.bg-grunge {
  background-image: url('/assets/_base/grunge.png');
}
